import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";

import Text from "../Text";
import Headspace from "../Headspace";
import Navigation from "../Navigation";
import StyledLink from "../StyledLink";
import StyledExternalLink from "../StyledExternalLink";
import { LargeContainer } from "../Grid";
import Box from "../Box";
import logoUrl from "../../images/logo.svg";
import PaginatonDividerUrl from "../../images/pagination-divider.svg";


const StyledBreadcrumbs = styled(Box)`
  border-left: ${props => props.noFirstBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.5)'};
  align-items: center;
  margin-left: 2rem;
  padding-left: 1rem;
  display:${props => props.hideDesktop ? 'none' : 'flex'};
  @media(max-width:768px) {
    background:${props => props.hideDesktop ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
    display:${props => props.hideDesktop ? 'flex' : 'none'};
    margin:0;
    padding:0;
    padding-top:15px;
    padding-bottom:15px;
    overflow:auto;
    white-space:nowrap;
  }
`;

const StyledBreadcrumbsLink = styled(StyledLink)`
  margin: 0 1.5rem;
  position: relative;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  :hover {
    text-decoration: underline;
  }
`;

const Devider = styled(Box)`
  height: 13px;
  position: absolute;
  right: -28px;
  top: 3px;
  width: 8px;
  display: block;
  background: url(${PaginatonDividerUrl});
`;

const LogoLink = styled(StyledExternalLink)`
  display: flex;
  align-items: center;
`;

const StyledH1 = styled.h1`
  font-size: 1.125rem;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
  margin: 0;
  padding-left: 1rem;
`;

const BadDayBanner = (
  <Box bg="#FF556E" display="flex" alignItems="center" justifyContent="center">
    <Text
      textAlign="center"
      color="white"
      fontFamily='"tt-norms-pro-regular","sans-serif"'
    >
      We are having some technical difficulties at the moment, and parts of the
      course will be inaccessible
    </Text>
  </Box>
);

const BreadcrumbsWrapper = ({ breadcrumbs, noFirstBorder, hideDesktop }) => {
  const breadcrumbsLength = breadcrumbs.length;
  const breadcrumb = breadcrumbs.map((breadcrumbs, index) => (
    <StyledBreadcrumbsLink
      key={index}
      to={breadcrumbs.path}
      color={breadcrumbsLength === index + 1 ? "#fff" : "rgba(255,255,255,0.5)"}
    >
      {breadcrumbs.title}
      {breadcrumbsLength !== index + 1 ? <Devider /> : ""}
    </StyledBreadcrumbsLink>
  ));
  return <StyledBreadcrumbs noFirstBorder={noFirstBorder} hideDesktop={hideDesktop}>{breadcrumb}</StyledBreadcrumbs>;
};

class Header extends React.Component {
  render() {
    const breadcrumbs = this.props.breadcrumbs;
    return (
      <Headspace>
        <Box w="100%">
          <LargeContainer>
            <Box
              display="flex"
              my={"1.5rem"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <LogoLink
                  mr={2}
                  color="white"
                  href={this.props.intl.formatMessage({ id: "routes.ABOUT" })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logoUrl} alt="logo" />
                  <StyledH1>Elements of AI</StyledH1>
                </LogoLink>

                {breadcrumbs ? (
                  <BreadcrumbsWrapper breadcrumbs={breadcrumbs} />
                ) : (
                  ""
                )}
              </Box>
              <Navigation />
            </Box>
          </LargeContainer>
          <BreadcrumbsWrapper breadcrumbs={breadcrumbs} noFirstBorder={true} hideDesktop={true} />

        </Box>
      </Headspace>
    );
  }
}
export default injectIntl(Header);
