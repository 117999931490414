import React from "react";
import PropTypes from "prop-types";
import { themeGet } from "styled-system";
import styled from "styled-components";
import StyledLink from "../StyledLink";
import Box from "../Box";

import SignOutButton from "../SignOut";

import { injectIntl, FormattedMessage } from "react-intl";

import withAuthentication from "../Session/withAuthentication";

const Navigation = (props, { authUser }) => (
  <div>{authUser ? <NavigationAuth /> : <NavigationNonAuth />}</div>
);

const Hamburger = styled.button`
  padding: 5px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 8px 0 0;
  overflow: visible;
  outline: none;
  position: relative;
  width: 60px;
  height: 25px;
  @media screen and (min-width: 38em) {
    width: 80px;
    display: block;
  }
  .hamburger-label {
    position: absolute;
    right: 38px;
    bottom: 3px;
    color: white;
    font-family: ${themeGet("fonts.TTNormsProBold")};
    font-weight: normal;
    display: none;
    @media screen and (min-width: 38em) {
      display: block;
    }
  }
  :hover {
    opacity: 0.8;
  }
  .hamburger-box {
    width: 24px;
    height: 18px;
    display: inline-block;
    position: absolute;
    bottom: 2px;
    right: 0;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 24px;
    height: 2px;
    background-color: #fff;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -7px;
  }
  .hamburger-inner::after {
    bottom: -7px;
  }
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }
  .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  }
  &.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out,
      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const NavLink = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  padding: 1rem 0;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(205, 294, 226, 0.15);
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 1);
  :hover {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`;
const ExternalNavLink = styled.a`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  padding: 1rem 0;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(205, 294, 226, 0.15);
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  :hover {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`;
const NavLinkFooter = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: inline-block;
  padding: 1rem 0;
  color: white;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.2s ease;
  margin-right: 1rem;
  :hover {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
`;

const StyledNavBox = styled.nav`
  position: absolute;
  width: 280px;
  background: #29264c;
  border-radius: 8px;
  padding: 1rem 2rem;
  position: absolute;
  transition: all 0.2s ease-in-out;
  top: ${props => (props.menuopen === "open" ? "40px" : "20px")};
  right: -0.4rem;
  opacity: ${props => (props.menuopen === "open" ? "1" : "0")};
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.08);
  pointer-events: ${props => (props.menuopen === "open" ? "all" : "none")};
  :before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #29264c;
    position: absolute;
    right: 16px;
    top: -10px;
  }
`;

Navigation.contextTypes = {
  authUser: PropTypes.object
};

const NavigationAuth = props => (
  <StyledNavBox
    menuopen={props.menuopen}
    pose={props.menuopen ? "visible" : "hidden"}
  >
    <NavLink to={props.intl.formatMessage({ id: "routes.LANDING" })}>
      <FormattedMessage id="globals.introToAI" />
    </NavLink>
    <ExternalNavLink
      href={props.intl.formatMessage({ id: "routes.BUILDING_AI" })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="globals.buildingAI" />
    </ExternalNavLink>
    <ExternalNavLink
      href={props.intl.formatMessage({ id: "routes.ABOUT" })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="globals.menu.about" />
    </ExternalNavLink>
    <ExternalNavLink
      href={props.intl.formatMessage({ id: "routes.FAQ" })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="globals.menu.faq" />
    </ExternalNavLink>
    <footer>
      <NavLinkFooter to={props.intl.formatMessage({ id: "routes.SIGN_IN" })}>
        <FormattedMessage id="globals.signIn" />
      </NavLinkFooter>
      <NavLinkFooter to={props.intl.formatMessage({ id: "routes.SIGN_UP" })}>
        <FormattedMessage id="globals.signUp" />
      </NavLinkFooter>
    </footer>
  </StyledNavBox>
);

const NavigationNonAuth = props => (
  <StyledNavBox
    menuopen={props.menuopen}
    pose={props.menuopen ? "visible" : "hidden"}
  >
    <NavLink to={props.intl.formatMessage({ id: "routes.LANDING" })}>
      <FormattedMessage id="globals.introToAI" />
    </NavLink>
    <ExternalNavLink
      href={props.intl.formatMessage({ id: "routes.BUILDING_AI" })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="globals.buildingAI" />
    </ExternalNavLink>
    <ExternalNavLink
      href={props.intl.formatMessage({ id: "routes.ABOUT" })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="globals.menu.about" />
    </ExternalNavLink>
    <ExternalNavLink
      href={props.intl.formatMessage({ id: "routes.FAQ" })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="globals.menu.faq" />
    </ExternalNavLink>

    <NavLink to={props.intl.formatMessage({ id: "routes.ACCOUNT" })}>
      <FormattedMessage id="globals.menu.myProfile" />
    </NavLink>
    <footer>
      <SignOutButton />
    </footer>
  </StyledNavBox>
);

class NavigationWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }
  render() {
    const { intl } = this.props;
    return (
      <Box position="relative">
        <Hamburger
          active={this.state.active}
          className={this.state.active ? "is-active" : ""}
          onClick={() => this.setState({ active: !this.state.active })}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
          <span className="hamburger-label">
            <FormattedMessage id="globals.menu" />
          </span>
        </Hamburger>
        {this.context.loggedIn ? (
          <NavigationNonAuth
            intl={intl}
            menuopen={this.state.active ? "open" : ""}
          />
        ) : (
          <NavigationAuth
            intl={intl}
            menuopen={this.state.active ? "open" : ""}
          />
        )}
      </Box>
    );
  }

  static contextTypes = {
    loggedIn: PropTypes.bool
  };
}

export default withAuthentication(injectIntl(NavigationWrapper));
