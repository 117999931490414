import styled from "styled-components";
import {
  textStyle,
  fontSize,
  fontFamily,
  color,
  lineHeight,
  space,
  textAlign,
  opacity
} from "styled-system";

const Text = styled.p`
  ${textStyle};
  ${fontSize};
  ${color};
  ${lineHeight};
  ${fontFamily};
  ${space};
  ${textAlign};
  ${opacity};
`;
export default Text;
