import styled from "styled-components";
import { textStyle, fontSize, color, lineHeight, space } from "styled-system";

const StyledExternalLink = styled.a`
  ${textStyle};
  ${fontSize};
  ${color};
  ${lineHeight};
  ${space};
  text-decoration: none;
`;

export default StyledExternalLink;
